import React from "react";
import _ from "lodash";
import numeral from "numeral";

import GroupTransactionsByDate from "component/GroupTransactionsByDate";

import order from "utils/order";

import { TransactionModel } from "model/TransactionModel";

interface GroupCategoryInterface {
    readonly total: number;
    readonly categoryName: string;
    readonly categoryType: string;
    readonly transactions: TransactionModel[];
}

interface PropsInterface {
    readonly transactions: TransactionModel[];
    readonly groupBy: string;
    readonly showTransactions: boolean;
}

function GroupTransactionsByCategory(props: PropsInterface) {
    const groups: any = {};

    props.transactions.forEach((transaction: TransactionModel) => {
        const groupKey: string = transaction.categoryId + transaction.type;

        if (!(groupKey in groups)) {
            groups[groupKey] = {
                total: 0,
                transactions: [],
                categoryType: transaction.type,
                categoryName: transaction.categoryName
            } as GroupCategoryInterface;
        }

        groups[groupKey].total += transaction.amount;
        groups[groupKey].transactions.push(transaction);
    });

    const aGroups = _.toArray(groups);

    order(aGroups, "categoryName", "ASC");

    order(aGroups, "categoryType", "DESC");

    return (
        <div>
            {aGroups.map((group: GroupCategoryInterface, index: number) => {
                return (
                    <div key={index} style={{ marginBottom: "40px" }}>
                        <div style={{ marginBottom: "25px", fontWeight: "bold", display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: "220px" }}>
                                {group.categoryName}
                            </div>
                            <div style={{ width: "140px", height: "1px" }} />
                            <div style={{ width: "140px", height: "1px" }} />
                            <div style={{ width: "140px", color: group.categoryType === "have" ? "#00851f" : "#c70000", textAlign: "right" }}>
                                {group.categoryType === "have" ? "+" : "-"} {numeral(Math.abs(group.total)).format('0.00')}
                            </div>
                        </div>

                        <GroupTransactionsByDate
                            transactions={group.transactions}
                            groupBy={props.groupBy}
                            showTransactions={props.showTransactions}
                            nested={1} />
                    </div>
                );
            })}
        </div>
    );
}

export default GroupTransactionsByCategory;