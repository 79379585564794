import React from "react";

import GroupTransactionsByDate from "component/GroupTransactionsByDate";
import GroupTransactionsByCategory from "component/GroupTransactionsByCategory";

import { TransactionModel } from "model/TransactionModel";

interface PropsInterface {
    readonly transactions: TransactionModel[];
    readonly groupBy: string;
    readonly groupByCategory: boolean;
    readonly showTransactions: boolean;
}

function TransactionsVisualizer(props: PropsInterface) {
    return props.groupByCategory ?
        <GroupTransactionsByCategory
            transactions={props.transactions}
            groupBy={props.groupBy}
            showTransactions={props.showTransactions} /> :
        <GroupTransactionsByDate
            transactions={props.transactions}
            groupBy={props.groupBy}
            showTransactions={props.showTransactions}
            nested={0} />;
}

export default TransactionsVisualizer;
