import axios from "axios";

import { TransactionFormModel } from "model/TransactionModel";
import { CategoryFormModel } from "model/CategoryModel";
import { WalletFormModel } from "model/WalletModel";

axios.defaults.baseURL = 'https://backend-wallet.roger08.cloud';
axios.defaults.responseType = 'json';

const Api = {
    login: (username: string, password: string) => {
        return unauth("POST", "/users/login", {
            username: username,
            password: password
        });
    },
    wallets: () => {
        return authGet("/personalWallets");
    },
    wallet: (id: string) => {
        return authGet("/personalWallets/" + id);
    },
    createWallet: (wallet: WalletFormModel) => {
        return authPost("/personalWallets", wallet);
    },
    updateWallet: (wallet: WalletFormModel) => {
        return authPut("/personalWallets/" + wallet.id, wallet);
    },
    balance: (id: string, atDate: string) => {
        return authGet("/personalWallets/" + id + "/balance?at=" + atDate);
    },
    categoriesGive: () => {
        return authGet("/personalCategoriesGive");
    },
    categoriesHave: () => {
        return authGet("/personalCategoriesHave");
    },
    categoryGive: (id: string) => {
        return authGet("/personalCategoriesGive/" + id);
    },
    categoryHave: (id: string) => {
        return authGet("/personalCategoriesHave/" + id);
    },
    createCategoryGive: (category: CategoryFormModel) => {
        return authPost("/personalCategoriesGive", category);
    },
    createCategoryHave: (category: CategoryFormModel) => {
        return authPost("/personalCategoriesHave", category);
    },
    updateCategoryGive: (category: CategoryFormModel) => {
        return authPut("/personalCategoriesGive/" + category.id, category);
    },
    updateCategoryHave: (category: CategoryFormModel) => {
        return authPut("/personalCategoriesHave/" + category.id, category);
    },
    transactionsGive: (id: string, fromDate: string, toDate: string) => {
        return authGet("/personalWallets/" + id + "/transactionsGive", { from: fromDate, to: toDate });
    },
    transactionsHave: (id: string, fromDate: string, toDate: string) => {
        return authGet("/personalWallets/" + id + "/transactionsHave", { from: fromDate, to: toDate });
    },
    createTransactionGive: (transaction: TransactionFormModel) => {
        return authPost("/personalTransactionsGive", transaction);
    },
    createTransactionHave: (transaction: TransactionFormModel) => {
        return authPost("/personalTransactionsHave", transaction);
    },
    updateTransactionGive: (transaction: TransactionFormModel) => {
        return authPut("/personalTransactionsGive/" + transaction.id, transaction);
    },
    updateTransactionHave: (transaction: TransactionFormModel) => {
        return authPut("/personalTransactionsHave/" + transaction.id, transaction);
    },
    deleteTransactionGive: (id: string) => {
        return authDelete("/personalTransactionsGive/" + id);
    },
    deleteTransactionHave: (id: string) => {
        return authDelete("/personalTransactionsHave/" + id);
    },
    transactionGive: (id: string) => {
        return authGet("/personalTransactionsGive/" + id);
    },
    transactionHave: (id: string) => {
        return authGet("/personalTransactionsHave/" + id);
    }
};

export default Api;

const authGet = (url: string, params?: any) => {
    return auth("GET", url, undefined, params);
};

const authPost = (url: string, body?: any) => {
    return auth("POST", url, body);
};

const authPut = (url: string, body?: any) => {
    return auth("PUT", url, body);
};

const authDelete = (url: string, params?: any) => {
    return auth("DELETE", url, undefined, params);
};

const auth = (method: string, url: string, body?: any, params?: any) => {
    return http(method, url, body, { "Authorization-header-custom": localStorage.getItem("userToken") ?? "" }, params);
};

const unauth = (method: string, url: string, body?: any) => {
    return http(method, url, body);
};

const http = (method: string, url: string, body?: any, headers?: any, params?: any) => {
    const request = {
        method: method,
        url: "/api" + url,
    } as any;

    if (body !== undefined) {
        request.data = JSON.stringify(body);
    }

    if (headers !== undefined) {
        request.headers = headers;
    }

    if (request.headers === undefined) {
        request.headers = {};
    }

    request.headers["Content-Type"] = "application/json";

    if (params !== undefined) {
        request.params = params;
    }

    return new Promise(function (resolve, reject) {
        axios(request)
            .then(response => {
                let data = null;

                if (response.data !== null && 'details' in response.data) {
                    data = response.data.details as any;
                }

                resolve(data);
            })
            .catch(error => {
                console.log(error);

                reject(error)
            });
    });
};
