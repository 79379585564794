import React from "react";
import { Link, useHistory } from "react-router-dom";

function Home() {
    let history = useHistory();

    if (localStorage.getItem("user") === "true") {
        history.push("/dashboard");
    }

    return (
        <div>
            <Link to="/login">Login</Link>
            <br />
            <br />
            <Link to="/register">Register</Link>
        </div>
    );
}

export default Home;
