import React from "react";

import { Link, useHistory, useParams } from "react-router-dom";

import _ from "lodash";

import moment from "moment";

import CategorySelector from "component/CategorySelector";

import { CategoryModel } from "model/CategoryModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import { setValueByPath, unsetValueByPath } from "utils/form";

interface PropsInterface {
    categories: CallableFunction;
    createTransaction: CallableFunction;
}

function NewTransactions(props: PropsInterface) {
    let history = useHistory();
    let { walletId } = useParams() as any;

    const [loading, setLoading] = React.useState(true);
    const [walletData, setWalletData] = React.useState({} as WalletModel);
    const [categories, setCategories] = React.useState([] as CategoryModel[]);

    React.useEffect(() => {
        setLoading(true);

        Promise.all([Api.wallet(walletId), props.categories()]).then((values) => {
            setWalletData(values[0] as WalletModel);

            setCategories(values[1] as CategoryModel[]);

            setLoading(false);
        });
    }, [walletId, props]);

    const [form, setForm] = React.useState({
        walletId: walletId,
        categoryId: "",
        transactions: [
            {
                date: moment().format("YYYY-MM-DD"),
                note: "",
                amount: 1.0,
            },
        ],
    });

    const onInputChange = (path: string, value: any) => {
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);

            setValueByPath(newForm, path, value);

            return newForm;
        });
    };

    const removeTransaction = React.useCallback((index: number) => {
        if (index > 0) {
            setForm((prevForm) => {
                const newForm = _.cloneDeep(prevForm);

                unsetValueByPath(newForm, "transactions." + index);

                return newForm;
            });
        }
    }, []);

    const addTransaction = React.useCallback(() => {
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);

            const lastTransactionIndex = newForm.transactions.length - 1;

            newForm.transactions.push({
                date: moment(newForm.transactions[lastTransactionIndex].date, "YYYY-MM-DD")
                    .add(1, "months")
                    .format("YYYY-MM-DD"),
                note: newForm.transactions[lastTransactionIndex].note,
                amount: newForm.transactions[lastTransactionIndex].amount,
            });

            return newForm;
        });
    }, []);

    const onSubmit = React.useCallback(() => {
        setLoading(true);

        const allPromises: Array<Promise<any>> = [];

        form.transactions.forEach((transaction) => {
            const transactionToCreate = {
                id: null,
                categoryId: form.categoryId,
                date: transaction.date,
                walletId: form.walletId,
                note: transaction.note,
                amount: transaction.amount,
                includeInGrandTotal: true,
            };

            console.log(transactionToCreate);

            allPromises.push(props.createTransaction(transactionToCreate));
        });

        Promise.all(allPromises).then((values) => {
            history.push("/wallets/" + walletId + "/transactions");
        });
    }, [form, history, props, walletId]);

    return !loading ? (
        <div>
            <strong>
                {walletData.name} | <i>{walletData.balance}</i>
            </strong>
            <br />
            <br />
            <Link to={"/wallets/" + walletData.id + "/transactions"}>Indietro</Link>
            <br />
            <br />
            <div style={{ marginBottom: "30px" }}>
                <CategorySelector
                    categories={categories}
                    categoryId={form.categoryId}
                    onChange={(categoryId: String) => onInputChange("categoryId", categoryId)}
                    excludeCategoryId={""}
                />
            </div>
            <div>
                {form.transactions.map((transaction, index) => {
                    return (
                        <TransactionPartial
                            transaction={transaction}
                            onInputChange={onInputChange}
                            removeTransaction={removeTransaction}
                            index={index}
                            key={index}
                        />
                    );
                })}
            </div>
            <div
                style={{
                    marginBottom: "20px",
                    borderBottom: "1px solid #000000",
                    paddingBottom: "10px",
                }}
            >
                <button onClick={() => addTransaction()}>{"ADD TRANSACTION"}</button>
            </div>
            <div>
                <button onClick={() => onSubmit()}>{"CREATE ALL TRANSACTION"}</button>
            </div>
        </div>
    ) : (
        <div>Loading</div>
    );
}

function TransactionPartial({
    transaction,
    onInputChange,
    index,
    removeTransaction,
}: {
    transaction: { date: string; note: string; amount: any };
    onInputChange: CallableFunction;
    index: number;
    removeTransaction: CallableFunction;
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "20px",
                borderBottom: "1px solid #000000",
                paddingBottom: "10px",
            }}
        >
            <div style={{ paddingLeft: "10px", paddingRight: "5px" }}>
                <input
                    type="text"
                    value={transaction.date}
                    onChange={(event) =>
                        onInputChange("transactions." + index + ".date", event.target.value)
                    }
                />
            </div>

            <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <input
                    type="text"
                    value={transaction.note}
                    onChange={(event) =>
                        onInputChange("transactions." + index + ".note", event.target.value)
                    }
                />
            </div>

            <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                <input
                    type="number"
                    value={transaction.amount}
                    onChange={(event) =>
                        onInputChange("transactions." + index + ".amount", event.target.value)
                    }
                    min={0.01}
                    step={0.01}
                />
            </div>

            <div style={{ paddingLeft: "5px", paddingRight: "10px" }}>
                {index > 0 ? (
                    <button onClick={() => removeTransaction(index)}>{"REMOVE TRANSACTION"}</button>
                ) : null}
            </div>
        </div>
    );
}

export default NewTransactions;
