import _ from "lodash";

export const getValueByPath = (obj: any, path: string) => {
    let i;

    const pathAsArray = path.split(".");

    for (i = 0; i < pathAsArray.length && obj !== undefined && obj !== null; i++) {
        if (pathAsArray[i] !== "") {
            obj = obj[pathAsArray[i]];
        }
    }

    return obj;
};

export const setValueByPath = (obj: any, path: string, value: any) => {
    let i;

    const pathAsArray = path.split(".");

    for (i = 0; i < pathAsArray.length - 1; i++) {
        if (pathAsArray[i] !== "") {
            obj = obj[pathAsArray[i]];
        }
    }

    obj[pathAsArray[i]] = value;
};

export const unsetValueByPath = (obj: any, path: string) => {
    let i;

    const pathAsArray = path.split(".");

    for (i = 0; i < pathAsArray.length - 1; i++) {
        if (pathAsArray[i] !== "") {
            obj = obj[pathAsArray[i]];
        }
    }

    if (_.isArray(obj)) {
        obj.splice(parseInt(pathAsArray[i], 10), 1);
    } else {
        delete obj[pathAsArray[i]];
    }
};
