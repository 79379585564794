import React, { useState } from "react";

import { CategoryModel } from "model/CategoryModel";

import CategoryLevel from "component/CategoryLevel";

import { calculateCategoryPath } from "utils/categories";

interface PropsInterface {
    categories: CategoryModel[],
    categoryId: string,
    onChange: CallableFunction,
    excludeCategoryId: string | null,
};

function CategorySelector(props: PropsInterface) {
    const [selectedCategoryPath, setSelectedCategoryPath] = useState(() => {
        if (props.categoryId.length !== 0) {
            const path = [] as Array<String>;

            calculateCategoryPath(props.categories, props.categoryId, path);

            return path.reverse();
        } else {
            return [] as Array<String>;
        }
    });

    const onClick = (newSelectedCategoryPath: Array<String>) => {
        setSelectedCategoryPath(newSelectedCategoryPath);

        props.onChange(newSelectedCategoryPath[newSelectedCategoryPath.length - 1]);
    };

    return <div>
        <CategoryLevel
            onClick={onClick}
            categories={props.categories}
            excludeCategoryId={props.excludeCategoryId}
            level={0}
            previousPath={[]}
            selectedCategoryPath={selectedCategoryPath} />
    </div>;
}

export default CategorySelector;