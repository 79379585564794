import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import CategoryForm from "component/CategoryForm";

import { CategoryModel, CategoryFormModel } from "model/CategoryModel";

interface PropsInterface {
    categories: CallableFunction,
    category: CallableFunction,
    updateCategory: CallableFunction
};

function Category(props: PropsInterface) {
    let history = useHistory();
    let { categoryId } = useParams() as any;

    const [loading, setLoading] = useState(true);
    const [category, setCategory] = useState({} as CategoryModel);
    const [categories, setCategories] = useState([] as CategoryModel[]);

    useEffect(() => {
        setLoading(true);

        Promise.all([props.category(categoryId), props.categories()]).then((values) => {
            setCategory(values[0] as CategoryModel);

            setCategories(values[1] as CategoryModel[]);

            setLoading(false);
        });
    }, [categoryId, props]);

    const onSubmit = (form: CategoryFormModel) => {
        setLoading(true);

        props.updateCategory(form).then(() => {
            history.push("/categories");
        });
    };

    return !loading ? (
        <div>
            <Link to={"/categories"}>Indietro</Link>
            <br />
            <br />
            <CategoryForm
                categories={categories}
                category={category}
                submitButtonText={"Update"}
                onSubmit={(form: CategoryFormModel) => { onSubmit(form); }} />
        </div>
    ) : (
            <div>Loading</div>
        );
}

export default Category;
