import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import moment from "moment";

import numeral from "numeral";

import { CSVLink } from "react-csv";

import TransactionsVisualizer from "component/TransactionsVisualizer";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import order from "utils/order";

function Transactions() {
    let { walletId } = useParams() as any;

    const [loading, setLoading] = useState(true);
    const [walletData, setWalletData] = useState({} as WalletModel);
    const [transactions, setTransactions] = useState([] as TransactionModel[]);
    const [fromDate, setFromDate] = useState(
        localStorage.getItem("fromDate-" + walletId) ??
            moment().subtract(1, "month").format("YYYY-MM-DD")
    );
    const [toDate, setToDate] = useState(
        localStorage.getItem("toDate-" + walletId) ?? moment().format("YYYY-MM-DD")
    );
    const [date, setDate] = useState({ from: fromDate, to: toDate });
    const [groupBy, setGroupBy] = useState(localStorage.getItem("groupBy-" + walletId) ?? "day");
    const [groupByCategory, setGroupByCategory] = useState(
        localStorage.getItem("groupByCategory-" + walletId) ?? "false"
    );
    const [showTransactions, setShowTransactions] = useState(
        localStorage.getItem("showTransactions-" + walletId) ?? "false"
    );
    const [initialBalance, setInitialBalance] = useState(0 as number);

    const onGroupByCategory = (event: any) => {
        const value = event.target.checked ? "true" : "false";

        localStorage.setItem("groupByCategory-" + walletId, value);

        setGroupByCategory(value);
    };

    const onSelectGroupBy = (value: string) => {
        localStorage.setItem("groupBy-" + walletId, value);

        setGroupBy(value);
    };

    const onShowTransactionsClick = (event: any) => {
        const value = event.target.checked ? "true" : "false";

        localStorage.setItem("showTransactions-" + walletId, value);

        setShowTransactions(value);
    };

    const onDateChange = (value: string, target: string) => {
        if (target === "from") {
            localStorage.setItem("fromDate-" + walletId, value);

            setFromDate(value);
        } else if (target === "to") {
            localStorage.setItem("toDate-" + walletId, value);

            setToDate(value);
        }
    };

    const onButtonSetFilterClicked = () => {
        setDate({ from: fromDate, to: toDate });
    };

    useEffect(() => {
        setLoading(true);

        Promise.all([
            Api.wallet(walletId),
            Api.transactionsGive(walletId, date.from, date.to),
            Api.transactionsHave(walletId, date.from, date.to),
            Api.balance(walletId, date.from),
        ]).then((values) => {
            const tmpTransactions: TransactionModel[] = [];

            setWalletData(values[0] as WalletModel);

            (values[1] as TransactionModel[]).forEach((transaction: TransactionModel) => {
                tmpTransactions.push(transaction);
            });

            (values[2] as TransactionModel[]).forEach((transaction: TransactionModel) => {
                tmpTransactions.push(transaction);
            });

            setInitialBalance(values[3] as number);

            setTransactions(tmpTransactions);

            setLoading(false);
        });
    }, [walletId, date]);

    const csvData = [...transactions];

    order(csvData, "date", "ASC");

    return !loading ? (
        <div>
            <Link to={"/wallets"}>Indietro</Link>
            <br />
            <br />
            {walletData.name} - {numeral(walletData.balance).format("0.00")} -{" "}
            {numeral(initialBalance).format("0.00") + " @ " + date.from}
            <br />
            <br />
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/balance-graph"}>
                        Grafico del bilancio
                    </Link>
                </div>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/bar-graph"}>Grafico a barre</Link>
                </div>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/diff-graph"}>
                        Grafico bilancio a barre
                    </Link>
                </div>
                <div>
                    <Link to={"/wallets/" + walletData.id + "/compare-graph"}>
                        Grafico comparazione
                    </Link>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/transactions-give/new"}>
                        Nuova uscita
                    </Link>
                </div>
                <div>
                    <Link to={"/wallets/" + walletData.id + "/transactions-have/new"}>
                        Nuova entrata
                    </Link>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "20px" }}>
                    <Link to={"/wallets/" + walletData.id + "/transactions-give/new-multiple"}>
                        Nuova uscita multipla
                    </Link>
                </div>
                <div>
                    <Link to={"/wallets/" + walletData.id + "/transactions-have/new-multiple"}>
                        Nuova entrata multipla
                    </Link>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={(event) => onGroupByCategory(event)}
                        checked={groupByCategory === "true"}
                    />{" "}
                    Group by category
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ padding: "7px", paddingLeft: 0, marginRight: "5px" }}>Group by</div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("none")}
                        checked={groupBy === "none"}
                    />{" "}
                    None
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("day")}
                        checked={groupBy === "day"}
                    />{" "}
                    Day
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("week")}
                        checked={groupBy === "week"}
                    />{" "}
                    Week
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("month")}
                        checked={groupBy === "month"}
                    />{" "}
                    Month
                </div>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={() => onSelectGroupBy("year")}
                        checked={groupBy === "year"}
                    />{" "}
                    Year
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div
                    style={{
                        backgroundColor: "#f1f1f1",
                        padding: "5px",
                        marginRight: "5px",
                        borderRadius: "5px",
                        paddingRight: "7px",
                    }}
                >
                    <input
                        type="checkbox"
                        onChange={(event) => onShowTransactionsClick(event)}
                        checked={showTransactions === "true"}
                    />{" "}
                    Show transactions
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div style={{ marginRight: "5px" }}>
                    <span>From</span>
                </div>
                <div style={{ marginRight: "15px" }}>
                    <input
                        type="text"
                        onChange={(event) => onDateChange(event.target.value, "from")}
                        value={fromDate}
                    />
                </div>
                <div style={{ marginRight: "5px" }}>
                    <span>To</span>
                </div>
                <div style={{ marginRight: "15px" }}>
                    <input
                        type="text"
                        onChange={(event) => onDateChange(event.target.value, "to")}
                        value={toDate}
                    />
                </div>
                <div>
                    <button onClick={() => onButtonSetFilterClicked()}>Set date filter</button>
                </div>
            </div>
            <CSVLink data={csvData}>Download CSV</CSVLink>;
            <br />
            <br />
            <TransactionsVisualizer
                transactions={transactions}
                groupBy={groupBy}
                groupByCategory={groupByCategory === "true"}
                showTransactions={showTransactions === "true"}
            />
        </div>
    ) : (
        <div>Loading</div>
    );
}

export default Transactions;
