import React from "react";

import { CategoryModel } from "model/CategoryModel";

import Category from "component/Category";

interface PropsInterface {
    categories: CategoryModel[],
    level: number,
    previousPath: Array<String>,
    selectedCategoryPath: Array<String>,
    onClick: CallableFunction,
    excludeCategoryId: string | null,
};

function CategoryLevel(props: PropsInterface) {
    let selectedCategory = {} as CategoryModel;

    if (props.selectedCategoryPath.length > props.level) {
        props.categories.forEach(category => {
            if (props.excludeCategoryId !== category.id && category.id === props.selectedCategoryPath[props.level]) {
                selectedCategory = category;
            }
        });
    }

    return <div>
        <div>
            {props.categories.map(category => {
                if (props.excludeCategoryId !== category.id) {
                    let backgroundColor = '#F1F1F1';
                    let textColor = 'black';
                    let onClick = () => props.onClick([...props.previousPath, category.id]);

                    if (selectedCategory?.id === category.id) {
                        textColor = 'white';

                        if (props.selectedCategoryPath.length === (props.level + 1)) {
                            onClick = () => props.onClick([...props.previousPath]);

                            backgroundColor = 'black';
                        } else {
                            backgroundColor = '#707070';
                        }
                    }

                    return <Category
                        onClick={onClick}
                        backgroundColor={backgroundColor}
                        textColor={textColor}
                        category={category}
                        key={category.id} />;
                } else {
                    return null;
                }
            })}
        </div>
        {
            selectedCategory?.id !== undefined ?
                <div style={{ paddingLeft: '20px' }}>
                    <CategoryLevel
                        categories={selectedCategory.children}
                        level={props.level + 1}
                        excludeCategoryId={props.excludeCategoryId}
                        previousPath={[...props.previousPath, selectedCategory.id]}
                        selectedCategoryPath={props.selectedCategoryPath}
                        onClick={props.onClick} />
                </div> :
                null
        }
    </div>;
}

export default CategoryLevel;