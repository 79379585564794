import React from "react";
import { Link } from "react-router-dom";

function Dashboard() {
    return (
        <div>
            {"Dashboard"}
            <br />
            <br />
            <Link to="/wallets">Wallets</Link>
            <br />
            <br />
            <Link to="/categories">Categories</Link>
            <br />
            <br />
            <Link to="/logout">Logout</Link>
        </div>
    );
}

export default Dashboard;
