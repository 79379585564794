import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

function Wallets() {
    const [loading, setLoading] = useState(true);
    const [wallets, setWallets] = useState([] as WalletModel[]);

    useEffect(() => {
        setLoading(true);

        Api.wallets().then((data) => {
            setWallets(data as WalletModel[]);

            setLoading(false);
        });
    }, []);

    return !loading ? (
        <div>
            <div key={"back"}>
                <Link to={"/"}>Indietro</Link>
            </div>

            <br />

            <span>Elenco portafogli - </span> <Link to={"/wallets/new"}>Nuovo portafoglio</Link>

            <br />
            <br />

            {wallets.map((wallet) => {
                return (
                    <div key={wallet.id}>
                        <Link to={"/wallets/" + wallet.id + "/transactions"}>{wallet.name}</Link>
                        <br />
                    </div>
                );
            })}
        </div>
    ) : (
            <div>Loading</div>
        );
}

export default Wallets;
