import "chartjs-adapter-moment";

import React from "react";

import { Link, useParams } from "react-router-dom";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";

import _ from "lodash";

import moment from "moment";

import InputText from "component/InputText";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import { setValueByPath, unsetValueByPath } from "utils/form";
import order from "utils/order";

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    type: "line",
    responsive: true,
    scales: {
        x: {
            type: "linear" as const,
            min: 0,
            max: 370,
        },
        y: {
            min: -2500,
            max: 11000,
        },
    },
};

function getTransactions(
    walletId: string,
    fromDate: string,
    toDate: string
): Promise<Array<TransactionModel>> {
    return new Promise((resolve, reject) => {
        Promise.all([
            Api.transactionsGive(walletId, fromDate, toDate),
            Api.transactionsHave(walletId, fromDate, toDate),
        ]).then((values) => {
            const transactions: Array<TransactionModel> = [];

            (values[0] as Array<TransactionModel>).forEach((transaction: TransactionModel) => {
                transactions.push(transaction);
            });

            (values[1] as Array<TransactionModel>).forEach((transaction: TransactionModel) => {
                transactions.push(transaction);
            });

            order(transactions, "date", "ASC");

            resolve(transactions);
        });
    });
}

type IntervalPropsType = {
    index: number;
    interval: { from: string; to: string };
    onInputChange: (path: string, value: string) => void;
    removeInterval: (index: number) => void;
};

function Interval(props: IntervalPropsType) {
    return (
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}>
            <InputText
                label={"From"}
                value={props.interval.from}
                onChange={(event) =>
                    props.onInputChange(`intervals.${props.index}.from`, event.target.value)
                }
            />

            <InputText
                label={"To"}
                value={props.interval.to}
                onChange={(event) =>
                    props.onInputChange(`intervals.${props.index}.to`, event.target.value)
                }
            />

            <div style={{ paddingTop: "17px" }}>
                <button onClick={() => props.removeInterval(props.index)}>
                    Elimina intervallo
                </button>
            </div>
        </div>
    );
}

type FormType = {
    intervals: Array<{ from: string; to: string }>;
};

type DatasetType = { borderColor: string; label: string; data: Array<{ x: number; y: number }> };

type DataType = {
    datasets: Array<DatasetType>;
};

const availableColors = [
    "204, 255, 0",
    "255, 204, 255",
    "204, 102, 0",
    "102, 0, 255",
    "102, 204, 0",
    "102, 204, 255",
    "0, 51, 204",
    "51, 0, 51",
];

function CompareGraph() {
    let { walletId } = useParams<any>();

    const [walletData, setWalletData] = React.useState<WalletModel | undefined>(undefined);

    const [data, setData] = React.useState<DataType>({
        datasets: [],
    });

    React.useEffect(() => {
        Api.wallet(walletId).then((response) => {
            setWalletData(response as WalletModel);
        });
    }, [walletId]);

    const [form, setForm] = React.useState<FormType>({
        intervals: [
            {
                from: moment().subtract(3, "year").format("YYYY-01-01"),
                to: moment().subtract(3, "year").format("YYYY-12-31"),
            },
            {
                from: moment().subtract(2, "year").format("YYYY-01-01"),
                to: moment().subtract(2, "year").format("YYYY-12-31"),
            },
            {
                from: moment().subtract(1, "year").format("YYYY-01-01"),
                to: moment().subtract(1, "year").format("YYYY-12-31"),
            },
            {
                from: moment().format("YYYY-01-01"),
                to: moment().format("YYYY-12-31"),
            },
        ],
    });

    const onInputChange = React.useCallback((path: string, value: string) => {
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);

            setValueByPath(newForm, path, value);

            return newForm;
        });
    }, []);

    const addInterval = React.useCallback(() => {
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);

            setValueByPath(newForm, `intervals.${newForm.intervals.length}`, {
                from: moment().format("YYYY-01-01"),
                to: moment().format("YYYY-12-31"),
            });

            return newForm;
        });
    }, []);

    const removeInterval = React.useCallback((index) => {
        setForm((prevForm) => {
            const newForm = _.cloneDeep(prevForm);

            unsetValueByPath(newForm, `intervals.${index}`);

            return newForm;
        });
    }, []);

    const loadGraphData = () => {
        if (walletData) {
            const promises: Array<Promise<Array<TransactionModel>>> = [];

            form.intervals.forEach((interval) => {
                promises.push(getTransactions(walletData.id, interval.from, interval.to));
            });

            Promise.all(promises).then((groups) => {
                const newData: DataType = {
                    datasets: [],
                };

                groups.forEach((transactions, i) => {
                    const intervalToUse = form.intervals[i];

                    const dataset: DatasetType = {
                        borderColor: `rgb(${availableColors[i]})`,
                        label: `Periodo ${intervalToUse.from} - ${intervalToUse.to}`,
                        data: [],
                    };

                    const dates: { [x: string]: { balance: number | null } } = {};

                    for (
                        let date = moment(intervalToUse.from, "YYYY-MM-DD");
                        date.isSameOrBefore(moment(intervalToUse.to, "YYYY-MM-DD"));
                        date.add(1, "day")
                    ) {
                        dates[date.format("YYYY-MM-DD")] = {
                            balance: null,
                        };
                    }

                    let lastBalance = 0;

                    transactions.forEach((transaction) => {
                        if (parseInt("" + transaction.amount) !== 10000) {
                            lastBalance +=
                                (transaction.type === "give" ? -1 : 1) * transaction.amount;

                            dates[moment(transaction.date).format("YYYY-MM-DD")].balance =
                                lastBalance;
                        }
                    });

                    let x = 0;

                    _.forEach(dates, (obj) => {
                        if (obj.balance !== null) {
                            dataset.data.push({
                                x: x,
                                y: obj.balance,
                            });
                        }

                        x++;
                    });

                    newData.datasets.push(dataset);
                });

                console.log(newData);

                setData(newData);
            });
        }
    };

    return (
        <>
            {walletData && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <Link to={`/wallets/${walletData.id}/transactions`}>Indietro</Link>
                    </div>

                    <div style={{ marginBottom: "1rem" }}>{walletData.name}</div>

                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
                        {form.intervals.map((interval, index) => {
                            return (
                                <Interval
                                    key={index}
                                    index={index}
                                    interval={interval}
                                    onInputChange={onInputChange}
                                    removeInterval={removeInterval}
                                />
                            );
                        })}

                        <div>
                            <button onClick={() => addInterval()}>Aggiungi intervallo</button>
                        </div>
                    </div>

                    <div style={{ marginBottom: "1rem" }}>
                        <button onClick={() => loadGraphData()}>Load graph</button>
                    </div>

                    <Line options={options} data={data} />
                </div>
            )}
        </>
    );
}

export default CompareGraph;
