import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

function PrivateRoute(props: RouteProps) {
    return localStorage.getItem("user") === "true" ? <Route {...props} /> : <Redirect to="/login" />;
}

PrivateRoute.defaultProps = { exact: true };

export default PrivateRoute;
