import React from "react";

import Transaction from "container/Transaction/Transaction";

import Api from "service/Api";

function TransactionHave() {
    return (
        <Transaction
            categories={Api.categoriesHave}
            transaction={Api.transactionHave}
            updateTransaction={Api.updateTransactionHave}
            deleteTransaction={Api.deleteTransactionHave}
            andNewUrl="/transactions-have/new"
        />
    );
}

export default TransactionHave;
