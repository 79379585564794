import React, { useState } from "react";

import moment from "moment";

import CategorySelector from "component/CategorySelector";

import { CategoryModel } from "model/CategoryModel";
import { TransactionFormModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

interface PropsInterface {
    categories: CategoryModel[];
    wallet: WalletModel;
    transaction?: TransactionFormModel;
    onSubmit: (form: TransactionFormModel, andNew: boolean) => void;
    onDelete?: (id: string) => void;
}

function TransactionForm(props: PropsInterface) {
    const { onDelete, transaction } = props;

    const [form, setForm] = useState({
        id: transaction ? transaction.id : null,
        walletId: props.wallet.id,
        note: transaction ? transaction.note : "",
        date: transaction
            ? moment(transaction.date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        amount: transaction ? transaction.amount : 0.01,
        categoryId: transaction ? transaction.categoryId : "",
        includeInGrandTotal: transaction ? transaction.includeInGrandTotal : true,
    } as TransactionFormModel);

    const onInputChange = (value: any, name: string) => {
        const updatedData = {
            [name]: value,
        };

        setForm((prevForm) => {
            return {
                ...prevForm,
                ...updatedData,
            };
        });
    };

    const { id } = form;

    return (
        <div>
            <CategorySelector
                categories={props.categories}
                categoryId={form.categoryId}
                onChange={(categoryId: String) => onInputChange(categoryId, "categoryId")}
                excludeCategoryId={""}
            />
            <br />
            <br />
            <input
                type="text"
                value={form.date}
                onChange={(event) => onInputChange(event.target.value, "date")}
            />
            <br />
            <br />
            <input
                type="text"
                value={form.note}
                onChange={(event) => onInputChange(event.target.value, "note")}
            />
            <br />
            <br />
            <input
                type="number"
                min={0.01}
                step={0.01}
                value={form.amount}
                onChange={(event) => onInputChange(event.target.value, "amount")}
            />
            <br />
            <br />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "row", columnGap: "20px" }}>
                        <button onClick={() => props.onSubmit(form, true)}>
                            {"Salva e nuova"}
                        </button>
                        <button onClick={() => props.onSubmit(form, false)}>{"Salva"}</button>
                    </div>
                </div>
                <div>
                    {id !== null && onDelete !== undefined && (
                        <button onClick={() => onDelete(id)}>Delete</button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TransactionForm;
