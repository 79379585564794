import React from "react";

import NewTransaction from "container/Transaction/NewTransaction";

import Api from "service/Api";

function NewTransactionHave() {
    return (
        <NewTransaction
            categories={Api.categoriesHave}
            createTransaction={Api.createTransactionHave}
            andNewUrl="/transactions-have/new"
        />
    );
}

export default NewTransactionHave;
