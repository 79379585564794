import React from "react";

import NewTransactions from "container/Transaction/NewTransactions";

import Api from "service/Api";

function NewTransactionsHave() {
    return <NewTransactions
        categories={Api.categoriesHave}
        createTransaction={Api.createTransactionHave} />
}

export default NewTransactionsHave;
