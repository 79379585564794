const order = (list: any[], field: string, order: string) => {
    const compare = (a: any, b: any) => {
        if (a[field] < b[field]) {
            return order === "DESC" ? 1 : -1;
        }

        if (a[field] > b[field]) {
            return order === "DESC" ? -1 : 1;
        }

        return 0;
    };

    list.sort(compare);
};

export default order;
