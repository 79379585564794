import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { WalletFormModel, WalletModel } from "model/WalletModel";

import Api from "service/Api";

function NewWallet() {
    let history = useHistory();
    const [formData, setFormData] = useState({ name: "", openingBalance: 0, includeTransactionsInGrandTotal: true } as WalletFormModel);

    const inputChangedHandler = (event: any, identifier: string) => {
        const updatedData = {
            [identifier]: event.target.value,
        };

        setFormData((prevFormData: WalletFormModel) => {
            return {
                ...prevFormData,
                ...updatedData,
            };
        });
    };

    const onFormSubmit = () => {
        Api.createWallet(formData).then((data) => {
            history.push("/wallets/" + (data as WalletModel).id);
        });
    };

    return (
        <div>
            <div key={"back"}>
                <Link to={"/wallets"}>Indietro</Link>
            </div>

            <br />

            <span>Nuovo portafoglio</span>

            <br /><br />

            <input type={"text"} value={formData.name} onChange={(event: any) => inputChangedHandler(event, "name")} />
            <br />
            <br />
            <input type={"number"} step={"0.01"} value={formData.openingBalance} onChange={(event: any) => inputChangedHandler(event, "openingBalance")} />
            <br />
            <br />
            <button onClick={() => onFormSubmit()}>Crea</button>
        </div>
    );
}

export default NewWallet;
