import React, { useState } from "react";

import CategorySelector from "component/CategorySelector";

import { CategoryFormModel, CategoryModel } from "model/CategoryModel";

interface PropsInterface {
    categories: CategoryModel[],
    category: CategoryModel | null,
    submitButtonText: string,
    onSubmit: CallableFunction
};

function CategoryForm(props: PropsInterface) {
    const [form, setForm] = useState({
        id: props.category ? props.category.id : null,
        name: props.category ? props.category.name : "",
        fatherId: props.category ? props.category.fatherId : null,
        includeTransactionsInGrandTotal: props.category ? props.category.includeTransactionsInGrandTotal : true,
    } as CategoryFormModel);

    const onInputChange = (value: any, name: string) => {
        const updatedData = {
            [name]: value,
        };

        setForm(prevForm => {
            return {
                ...prevForm,
                ...updatedData
            };
        });
    };

    return (
        <div>
            <CategorySelector
                categories={props.categories}
                categoryId={form.fatherId ?? ""}
                excludeCategoryId={form.id ?? ""}
                onChange={(fatherId: String) => onInputChange(fatherId, "fatherId")} />
            <br />
            <br />
            <input type="text" value={form.name} onChange={(event) => onInputChange(event.target.value, "name")} />
            <br />
            <br />
            <button onClick={() => props.onSubmit(form)}>{props.submitButtonText}</button>
        </div>
    );
}

export default CategoryForm;