import React from "react";

export interface OnChangeInterface {
    (event: React.ChangeEvent<HTMLInputElement>): void;
}

export type PropsType = {
    label: string;
    value: string | number | undefined;
    onChange: OnChangeInterface;
};

function InputText(props: PropsType) {
    return (
        <div>
            <div style={{ marginRight: "5px" }}>
                <span>{props.label}</span>
            </div>
            <div style={{ marginRight: "15px" }}>
                <input type="text" onChange={props.onChange} value={props.value} />
            </div>
        </div>
    );
}

export default InputText;
