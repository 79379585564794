import React from "react";

import NewCategory from "container/Category/NewCategory";

import Api from "service/Api";

function NewCategoryHave() {
    return <NewCategory categories={Api.categoriesHave} createCategory={Api.createCategoryHave} />
}

export default NewCategoryHave;
