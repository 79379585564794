import React from "react";
import { Link } from "react-router-dom";

function Register() {
    return (
        <div>
            {"Register"}
            <br />
            <br />
            <Link to="/">Indietro</Link>
        </div>
    );
}

export default Register;
