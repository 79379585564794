import React from "react";

import Category from "container/Category/Category";

import Api from "service/Api";

function CategoryHave() {
    return <Category
        categories={Api.categoriesHave}
        category={Api.categoryHave}
        updateCategory={Api.updateCategoryHave} />
}

export default CategoryHave;
