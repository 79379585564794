import React from "react";
import { Link } from "react-router-dom";
import numeral from "numeral";
import moment from "moment";

import { TransactionModel } from "model/TransactionModel";

import order from "utils/order";

interface PropsInterface {
    readonly transactions: TransactionModel[];
    readonly nested: number;
}

function PlainTransactions(props: PropsInterface) {
    order(props.transactions, "date", "DESC");

    const paddingLeft = props.nested * 15;

    return (
        <div>
            {props.transactions.map((transaction: TransactionModel) => {
                return (
                    <div key={transaction.id} style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                        <div style={{ width: (220 - paddingLeft) + "px", paddingLeft: paddingLeft + "px" }}>
                            <Link to={"/wallets/" + transaction.walletId + "/transactions-" + transaction.type + "/" + transaction.id}>{transaction.categoryName}</Link>
                        </div>
                        {transaction.type === "give" ? <div style={{ width: "140px", height: "1px" }}></div> : null}
                        <div style={{ width: "140px", color: transaction.type === "have" ? "#00851f" : "#c70000", textAlign: "right" }}>
                            {numeral(transaction.amount).format('0.00')}
                        </div>
                        {transaction.type === "have" ? <div style={{ width: "140px", height: "1px" }}></div> : null}
                        <div style={{ width: "140px", height: "1px" }}></div>
                        <div style={{ width: "140px", textAlign: "right" }}>
                            {moment(transaction.date).format("YYYY-MM-DD")}
                        </div>
                        <div style={{ paddingLeft: "35px" }}>
                            {transaction.note}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default PlainTransactions;
