import React from "react";

import Category from "container/Category/Category";

import Api from "service/Api";

function CategoryGive() {
    return <Category
        categories={Api.categoriesGive}
        category={Api.categoryGive}
        updateCategory={Api.updateCategoryGive} />
}

export default CategoryGive;
