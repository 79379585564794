import React from "react";

import NewTransaction from "container/Transaction/NewTransaction";

import Api from "service/Api";

function NewTransactionGive() {
    return (
        <NewTransaction
            categories={Api.categoriesGive}
            createTransaction={Api.createTransactionGive}
            andNewUrl="/transactions-give/new"
        />
    );
}

export default NewTransactionGive;
