import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";

import CategoryForm from "component/CategoryForm";

import { CategoryFormModel, CategoryModel } from "model/CategoryModel";

interface PropsInterface {
    categories: CallableFunction;
    createCategory: CallableFunction;
}

function NewCategory(props: PropsInterface) {
    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([] as CategoryModel[]);

    useEffect(() => {
        setLoading(true);

        Promise.all([props.categories()]).then((values) => {
            setCategories(values[0] as CategoryModel[]);

            setLoading(false);
        });
    }, [props]);

    const onSubmit = (form: CategoryFormModel) => {
        setLoading(true);

        props.createCategory(form).then(() => {
            history.push("/categories");
        });
    };

    return !loading ? (
        <div>
            <Link to={"/categories"}>Indietro</Link>
            <br />
            <br />
            <CategoryForm
                categories={categories}
                category={null}
                submitButtonText={"Create"}
                onSubmit={(form: CategoryFormModel) => {
                    onSubmit(form);
                }}
            />
        </div>
    ) : (
        <div>Loading</div>
    );
}

export default NewCategory;
