import React from "react";

import NewCategory from "container/Category/NewCategory";

import Api from "service/Api";

function NewCategoryGive() {
    return <NewCategory categories={Api.categoriesGive} createCategory={Api.createCategoryGive} />
}

export default NewCategoryGive;
