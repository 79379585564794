import React from "react";

import Transaction from "container/Transaction/Transaction";

import Api from "service/Api";

function TransactionGive() {
    return (
        <Transaction
            categories={Api.categoriesGive}
            transaction={Api.transactionGive}
            updateTransaction={Api.updateTransactionGive}
            deleteTransaction={Api.deleteTransactionGive}
            andNewUrl="/transactions-give/new"
        />
    );
}

export default TransactionGive;
