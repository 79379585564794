import React from "react";
import moment from "moment";
import _ from "lodash";
import numeral from "numeral";

import PlainTransactions from "component/PlainTransactions";

import order from "utils/order";

import { TransactionModel } from "model/TransactionModel";

interface GroupInterface {
    readonly totalHave: number;
    readonly totalGive: number;
    readonly orderingDate: string;
    readonly formattedDate: string;
    readonly transactions: TransactionModel[];
}

interface PropsInterface {
    readonly transactions: TransactionModel[];
    readonly groupBy: string;
    readonly showTransactions: boolean;
    readonly nested: number;
}

function GroupTransactionsByDate(props: PropsInterface) {
    const mapping: any = {
        day: "YYYY-MM-DD",
        week: "YYYY-WW",
        month: "YYYY-MM",
        year: "YYYY",
    };

    if (props.groupBy in mapping) {
        const groups: any = {};

        props.transactions.forEach((transaction: TransactionModel) => {
            const groupKey: string = moment(transaction.date).format(mapping[props.groupBy]);

            if (!(groupKey in groups)) {
                groups[groupKey] = {
                    totalHave: 0,
                    totalGive: 0,
                    transactions: [],
                    orderingDate: moment(transaction.date).format(mapping[props.groupBy]),
                    formattedDate: moment(transaction.date).format(mapping[props.groupBy]),
                } as GroupInterface;
            }

            if (transaction.type === "give") {
                groups[groupKey].totalGive += transaction.amount;
            } else {
                groups[groupKey].totalHave += transaction.amount;
            }

            groups[groupKey].transactions.push(transaction);
        });

        const aGroups = _.toArray(groups);

        order(aGroups, "orderingDate", "DESC");

        const paddingLeft = props.nested * 15;

        return (
            <div>
                {aGroups.map((group: GroupInterface, index: number) => {
                    const groupBalance = group.totalHave - group.totalGive;

                    return (
                        <div key={index} style={{ marginBottom: "25px" }}>
                            <div style={{ marginBottom: "15px", fontWeight: "bold", display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: (220 - paddingLeft) + "px", paddingLeft: paddingLeft + "px" }}>
                                    {group.formattedDate}
                                </div>
                                <div style={{ width: "140px", color: "#00851f", textAlign: "right" }}>
                                    + {numeral(group.totalHave).format('0.00')}
                                </div>
                                <div style={{ width: "140px", color: "#c70000", textAlign: "right" }}>
                                    - {numeral(group.totalGive).format('0.00')}
                                </div>
                                <div style={{ width: "140px", color: groupBalance >= 0 ? "#00851f" : "#c70000", textAlign: "right" }}>
                                    {groupBalance >= 0 ? "+" : "-"} {numeral(Math.abs(groupBalance)).format('0.00')}
                                </div>
                            </div>

                            {props.showTransactions ? <PlainTransactions transactions={group.transactions} nested={props.nested + 1} /> : null}
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return props.showTransactions ? <PlainTransactions transactions={props.transactions} nested={props.nested + 1} /> : null;
    }
}

export default GroupTransactionsByDate;