import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import Api from "service/Api";

function Login() {
    let history = useHistory();
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const handleLoginClick = async () => {
        Api.login(emailValue, passwordValue).then((data: any) => {
            localStorage.setItem("userToken", data.token);

            localStorage.setItem("user", "true");

            history.push("/dashboard");
        });
    };

    const handleEmailChange = (event: any) => {
        setEmailValue(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPasswordValue(event.target.value);
    };

    return (
        <div>
            <input type="text" onChange={handleEmailChange} value={emailValue} placeholder={"Email"} />
            <br />
            <br />
            <input type="password" onChange={handlePasswordChange} value={passwordValue} placeholder={"Password"} />
            <br />
            <br />
            <button onClick={handleLoginClick}>LOGIN</button>
            <br />
            <br />
            <Link to="/">Indietro</Link>
        </div>
    );
}

export default Login;
