import "styles/App.css";

import React from "react";

import { Redirect, Route, Router, Switch } from "react-router-dom";

import Categories from "container/Category/Categories";
import CategoryGive from "container/Category/CategoryGive";
import CategoryHave from "container/Category/CategoryHave";
import NewCategoryGive from "container/Category/NewCategoryGive";
import NewCategoryHave from "container/Category/NewCategoryHave";
import Dashboard from "container/Dashboard";
import Home from "container/Home";
import Login from "container/Login";
import Logout from "container/Logout";
import Register from "container/Register";
import BalanceGraph from "container/Transaction/BalanceGraph";
import CompareGraph from "container/Transaction/CompareGraph";
import BarGraph from "container/Transaction/BarGraph";
import DiffGraph from "container/Transaction/DiffGraph";
import NewTransactionGive from "container/Transaction/NewTransactionGive";
import NewTransactionHave from "container/Transaction/NewTransactionHave";
import NewTransactionsGive from "container/Transaction/NewTransactionsGive";
import NewTransactionsHave from "container/Transaction/NewTransactionsHave";
import TransactionGive from "container/Transaction/TransactionGive";
import TransactionHave from "container/Transaction/TransactionHave";
import Transactions from "container/Transaction/Transactions";
import EditWallet from "container/Wallet/EditWallet";
import NewWallet from "container/Wallet/NewWallet";
import Wallets from "container/Wallet/Wallets";

import PrivateRoute from "service/Auth/PrivateRoute";

import { HISTORY } from "utils/const";

function App() {
    return (
        <div style={{ padding: "20px" }}>
            <Router history={HISTORY}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/register" component={Register} />

                    <PrivateRoute exact path="/dashboard" component={Dashboard} />

                    <PrivateRoute exact path="/categories" component={Categories} />

                    <PrivateRoute exact path="/categories-give/new" component={NewCategoryGive} />
                    <PrivateRoute exact path="/categories-have/new" component={NewCategoryHave} />

                    <PrivateRoute
                        exact
                        path="/categories-give/:categoryId"
                        component={CategoryGive}
                    />
                    <PrivateRoute
                        exact
                        path="/categories-have/:categoryId"
                        component={CategoryHave}
                    />

                    <PrivateRoute exact path="/wallets" component={Wallets} />
                    <PrivateRoute exact path="/wallets/new" component={NewWallet} />
                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/transactions"
                        component={Transactions}
                    />
                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/balance-graph"
                        component={BalanceGraph}
                    />
                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/compare-graph"
                        component={CompareGraph}
                    />
                    <PrivateRoute exact path="/wallets/:walletId/bar-graph" component={BarGraph} />
                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/diff-graph"
                        component={DiffGraph}
                    />
                    <PrivateRoute exact path="/wallets/:walletId/edit" component={EditWallet} />

                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/transactions-give/new"
                        component={NewTransactionGive}
                    />
                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/transactions-have/new"
                        component={NewTransactionHave}
                    />

                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/transactions-give/new-multiple"
                        component={NewTransactionsGive}
                    />
                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/transactions-have/new-multiple"
                        component={NewTransactionsHave}
                    />

                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/transactions-give/:transactionId"
                        component={TransactionGive}
                    />
                    <PrivateRoute
                        exact
                        path="/wallets/:walletId/transactions-have/:transactionId"
                        component={TransactionHave}
                    />

                    <Redirect to="/wallets" />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
