import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { CategoryModel } from "model/CategoryModel";
import CategoriesList from "component/CategoriesList";

import Api from "service/Api";

function Categories() {
    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [categoriesGive, setCategoriesGive] = useState([] as CategoryModel[]);
    const [categoriesHave, setCategoriesHave] = useState([] as CategoryModel[]);

    useEffect(() => {
        setLoading(true);

        Promise.all([Api.categoriesGive(), Api.categoriesHave()]).then((values) => {
            setCategoriesGive(values[0] as CategoryModel[]);

            setCategoriesHave(values[1] as CategoryModel[]);

            setLoading(false);
        });
    }, []);

    return !loading ? (
        <div>
            <Link to={"/dashboard"}>Indietro</Link>
            <br />
            <br />
            <span>Dare - </span> <Link to={"/categories-give/new"}>Nuova categoria</Link>
            <br />
            <br />
            <CategoriesList
                categories={categoriesGive}
                onClick={(categoryId: string) => history.push("/categories-give/" + categoryId)} />
            <br />
            <br />
            <span>Avere - </span> <Link to={"/categories-have/new"}>Nuova categoria</Link>
            <br />
            <br />
            <CategoriesList
                categories={categoriesHave}
                onClick={(categoryId: string) => history.push("/categories-have/" + categoryId)} />
        </div>
    ) : (
            <div>Loading</div>
        );
}

export default Categories;
