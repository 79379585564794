import React from "react";

import { CategoryModel } from "model/CategoryModel";

interface PropsInterface {
    category: CategoryModel,
    onClick: CallableFunction,
    backgroundColor: string,
    textColor: string
};

function Category(props: PropsInterface) {
    return <span onClick={() => props.onClick(props.category.id)}
        style={{
            display: 'inline-block',
            padding: '5px 10px',
            borderRadius: '15px',
            lineHeight: '21px',
            fontSize: '15px',
            marginBottom: '10px',
            marginLeft: '5px',
            marginRight: '5px',
            backgroundColor: props.backgroundColor,
            color: props.textColor
        }}>
        {props.category.name}
    </span>;
}

export default Category;