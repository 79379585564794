import React from "react";

import NewTransactions from "container/Transaction/NewTransactions";

import Api from "service/Api";

function NewTransactionsGive() {
    return <NewTransactions
        categories={Api.categoriesGive}
        createTransaction={Api.createTransactionGive} />
}

export default NewTransactionsGive;
