import "chartjs-adapter-moment";

import React from "react";

import { Link, useParams } from "react-router-dom";

import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";

import _ from "lodash";

import moment from "moment";

import InputText from "component/InputText";

import { TransactionModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

import order from "utils/order";

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    scales: {
        xAxis: {
            type: "time" as const,
        },
    },
};

function BalanceGraph() {
    let { walletId } = useParams<any>();

    const [walletData, setWalletData] = React.useState<WalletModel | undefined>(undefined);

    React.useEffect(() => {
        Api.wallet(walletId).then((response) => {
            setWalletData(response as WalletModel);
        });
    }, [walletId]);

    const [fromDate, setFromDate] = React.useState(
        localStorage.getItem("fromGraphDate-" + walletId) ??
            moment().subtract(1, "month").format("YYYY-MM-DD")
    );
    const [toDate, setToDate] = React.useState(
        localStorage.getItem("toGraphDate-" + walletId) ?? moment().format("YYYY-MM-DD")
    );

    const [data, setData] = React.useState<any>({
        datasets: [
            {
                label: "Dataset 1",
                data: [],
            },
        ],
    });

    const onDateChange = (value: string, target: string) => {
        if (target === "from") {
            localStorage.setItem("fromGraphDate-" + walletId, value);

            setFromDate(value);
        } else if (target === "to") {
            localStorage.setItem("toGraphDate-" + walletId, value);

            setToDate(value);
        }
    };

    const loadGraphData = () => {
        if (walletData) {
            console.log("PROMISE.ALL");

            Promise.all([
                Api.transactionsGive(walletData.id, fromDate, toDate),
                Api.transactionsHave(walletData.id, fromDate, toDate),
            ]).then((values) => {
                console.log("PROMISE.THEN");

                const transactions: TransactionModel[] = [];

                (values[0] as TransactionModel[]).forEach((transaction: TransactionModel) => {
                    transactions.push(transaction);
                });

                (values[1] as TransactionModel[]).forEach((transaction: TransactionModel) => {
                    transactions.push(transaction);
                });

                setData((prevData: any) => {
                    console.log("PROMISE.SETDATA");

                    const newData = _.cloneDeep(prevData);

                    newData.datasets[0].data = [];

                    const groups: {
                        [x: string]: {
                            balance: number;
                            date: moment.Moment;
                            dateFormatted: string;
                        };
                    } = {};

                    order(transactions, "date", "DESC");

                    transactions.forEach((transaction: TransactionModel) => {
                        const groupKey: string = moment(transaction.date).format("YYYY-MM-DD");

                        if (!(groupKey in groups)) {
                            groups[groupKey] = {
                                balance: 0,
                                date: moment(transaction.date),
                                dateFormatted: moment(transaction.date).format("YYYY-MM-DD"),
                            };
                        }

                        if (transaction.type === "give") {
                            groups[groupKey].balance -= transaction.amount;
                        } else {
                            groups[groupKey].balance += transaction.amount;
                        }
                    });

                    _.reduce(
                        groups,
                        (lastBalance, group) => {
                            newData.datasets[0].data.push({
                                x: group.date,
                                y: lastBalance,
                            });

                            return lastBalance - group.balance;
                        },
                        walletData.balance
                    );

                    return newData;
                });
            });
        }
    };

    return (
        <>
            {walletData && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <Link to={`/wallets/${walletData.id}/transactions`}>Indietro</Link>
                    </div>

                    <div style={{ marginBottom: "1rem" }}>{walletData.name}</div>

                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
                        <InputText
                            label={"From"}
                            value={fromDate}
                            onChange={(event) => onDateChange(event.target.value, "from")}
                        />

                        <InputText
                            label={"To"}
                            value={toDate}
                            onChange={(event) => onDateChange(event.target.value, "to")}
                        />
                    </div>

                    <div style={{ marginBottom: "1rem" }}>
                        <button onClick={() => loadGraphData()}>Load graph</button>
                    </div>

                    <Line options={options} data={data} />
                </div>
            )}
        </>
    );
}

export default BalanceGraph;
