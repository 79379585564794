import React, { useEffect, useState } from "react";

import { Link, useHistory, useParams } from "react-router-dom";

import TransactionForm from "component/TransactionForm";

import { CategoryModel } from "model/CategoryModel";
import { TransactionFormModel } from "model/TransactionModel";
import { WalletModel } from "model/WalletModel";

import Api from "service/Api";

interface PropsInterface {
    categories: CallableFunction;
    createTransaction: CallableFunction;
    andNewUrl: string;
}

function NewTransaction(props: PropsInterface) {
    let history = useHistory();
    let { walletId } = useParams() as any;

    const [loading, setLoading] = useState(true);
    const [walletData, setWalletData] = useState({} as WalletModel);
    const [categories, setCategories] = useState([] as CategoryModel[]);

    useEffect(() => {
        setLoading(true);

        Promise.all([Api.wallet(walletId), props.categories()]).then((values) => {
            setWalletData(values[0] as WalletModel);

            setCategories(values[1] as CategoryModel[]);

            setLoading(false);
        });
    }, [walletId, props]);

    const onSubmit = (form: TransactionFormModel, andNew: boolean) => {
        setLoading(true);

        props.createTransaction(form).then(() => {
            if (andNew) {
                history.push("/wallets/" + walletId + props.andNewUrl);
            } else {
                history.push("/wallets/" + walletId + "/transactions");
            }
        });
    };

    return !loading ? (
        <div>
            <strong>
                {walletData.name} | <i>{walletData.balance}</i>
            </strong>
            <br />
            <br />
            <Link to={"/wallets/" + walletData.id + "/transactions"}>Indietro</Link>
            <br />
            <br />
            <br />
            <TransactionForm categories={categories} wallet={walletData} onSubmit={onSubmit} />
        </div>
    ) : (
        <div>Loading</div>
    );
}

export default NewTransaction;
