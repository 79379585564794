import { CategoryModel } from "model/CategoryModel";

export const calculateCategoryPath = (categories: Array<CategoryModel>, categoryId: string, path: Array<String>): boolean | null => {
    for (let i = 0; i < categories.length; i++) {
        if (categories[i].id === categoryId) {
            path.push(categoryId);

            return true;
        } else {
            const result = calculateCategoryPath(categories[i].children, categoryId, path);

            if (result === true) {
                path.push(categories[i].id);

                return result;
            }
        }
    }

    return null;
};