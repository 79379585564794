import React from "react";

import { CategoryModel } from "model/CategoryModel";

import Category from "component/Category";

interface PropsInterface {
    categories: CategoryModel[],
    onClick: CallableFunction,
};

function CategoriesList(props: PropsInterface) {
    return <div>
        {props.categories.map((category: CategoryModel) => {
            return <div key={category.id}>
                <Category
                    category={category}
                    onClick={props.onClick}
                    textColor={"black"}
                    backgroundColor={"#F1F1F1"} />
                {category.children.length > 0
                    ? <div style={{ paddingLeft: "20px" }}><CategoriesList categories={category.children} onClick={props.onClick} /></div>
                    : null
                }
            </div>
        })}
    </div>;
}

export default CategoriesList;